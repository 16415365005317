import React from 'react'
import './Homepage.scss'

export default function Homepage() {
	return (
		<div id='homepage'>
			<div className='title-wrapper'>
				<h1>Kunstrasen für Walschleben.</h1>
				<div> <a href="#sponsors">Zu den Sponsoren</a></div>
			</div>
		</div>
	)
}
